import { Vue, Component, Watch } from 'vue-property-decorator'
import { Container } from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { mapState } from 'vuex'
@Component({
  name: 'GtrCompanyGlobalEventSearch',
  computed: {
    ...mapState('company', ['company', 'event_global_search'])
  }
})
export default class GtrCompanyGlobalEventSearch extends Vue {
  event_global_search!: Record<string, any>;

  data () {
    return {
      loading: false,
      name: null,
      searchResults: null
    }
  }

  @Watch('event_global_search')
  onEventGlobalSearchChange () {
    this.$data.searchResults = this.event_global_search
    this.$data.loading = false
    this.$store.dispatch('common/hideLoader')
  }

  async search () {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      this.$data.loading = true
      const payload = this.$data.name
      await this.$store.dispatch('company/saveEventGlobalSearch', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }
}
